<script lang="ts" setup>
// import { useWallet } from '@solana/wallet-adapter-vue'
import i18next from 'i18next'
import { APP_URL, DEV_APP_URL } from '~/config'
// import { FeedbackBtn } from '~/features/feedback'

const route = useRoute()

const i18nReady = ref(false)

const { autoConnect } = useAutoConnect()
const appUrl = import.meta.env.PROD ? APP_URL : DEV_APP_URL
const originUrl = `${appUrl}${route.fullPath}`
// const { publicKey } = useWallet()

useHead({
  title: import.meta.env.VITE_APP_TITLE,
  titleTemplate: title => `${title} | JPool`,
  meta: [
    { name: 'description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { name: 'keywords', content: import.meta.env.VITE_APP_KEYWORDS },
    { property: 'og:title', content: 'JPool' },
    { property: 'og:description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: `${appUrl}/img/logo.svg` },
    { property: 'og:url', content: originUrl },
    { name: 'twitter:title', content: import.meta.env.VITE_APP_TITLE },
    { name: 'twitter:creator', content: '@JPoolSolana' },
  ],
})

const auth = useAuthStore()
const isPasswordProtected = computed(() => auth.isEnabled && !auth.isAuthenticated)

const { initStorageVersion } = useStorageVersion()

function initI18Next() {
  if (i18next.resolvedLanguage === 'en') {
    i18nReady.value = true
  } else {
    setTimeout(() => i18nReady.value = true, 500)
  }
}

onBeforeMount(() => {
  initHcApi()
  initWallet()
  initStorageVersion()

  if (i18next.isInitialized) {
    initI18Next()
  } else {
    i18next.on('initialized', () => {
      initI18Next()
    })
  }
})

// function setAnalitics(event: 'click_feedback' | 'show_feedback') {
//   Analytics.track({
//     event,
//     address: publicKey.value?.toBase58() || 'Not logged user',
//   })
// }

watch(i18nReady, () => {
  const body = document.querySelector('body') as HTMLElement
  if (body) {
    body.style.transition = 'opacity 0.3s ease-in-out'
    body.style.opacity = '1'
  }
})

onMounted(async () => {
  await autoConnect()
})
</script>

<template>
  <banner v-if="!isPasswordProtected" />
  <password-protect v-if="isPasswordProtected" />
  <router-view v-else />
  <b-toast-orchestrator />
  <to-top-btn />
  <!-- <feedback-btn @redirect="setAnalitics('click_feedback')" @first-open="setAnalitics('show_feedback')" /> -->
</template>
