import type { UserModule } from '~/types'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

import SolanaWalletAdapter from '@solana/wallet-adapter-vue'
import {
  BackpackWalletAdapter,
  BitgetWalletAdapter,
  BybitWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  ExodusWalletAdapter,
  FractalWalletAdapter,
  GateWalletAdapter,
  KeystoneWalletAdapter,
  LedgerWalletAdapter,
  MagicEdenWalletAdapter,
  MathWalletAdapter,
  MoongateWalletAdapter2,
  NightlyWalletAdapter,
  NufiWalletAdapter,
  OKXWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TipLinkWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
  TrezorWalletAdapter,
  TrustWalletAdapter,
  WalletConnectWalletAdapter,
  XDEFIWalletAdapter,
  // AvanaWalletAdapter,
  // BitpieWalletAdapter,
  // CoinhubWalletAdapter,
  // HuobiWalletAdapter,
  // HyperPayWalletAdapter,
  // KrystalWalletAdapter,
  // LedgerWalletAdapter,
  // NekoWalletAdapter,
  // OntoWalletAdapter,
  // SafePalWalletAdapter,
  // SaifuWalletAdapter,
  // SalmonWalletAdapter,
  // SkyWalletAdapter,
  // SolongWalletAdapter,
  // SpotWalletAdapter,
  // TokenaryWalletAdapter,
} from 'wallets'

export const install: UserModule = async ({ app }) => {
  app.use(SolanaWalletAdapter, {
    wallets: [
      new MoongateWalletAdapter2({
        authMode: 'Ethereum',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Google',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Apple',
        position: 'top-right',
      }),
      new TipLinkWalletAdapter({
        clientId: import.meta.env.TIP_WALLET_KEY ?? '',
        title: 'JPool',
        theme: 'system',
      }),
      new WalletConnectWalletAdapter({
        network: WalletAdapterNetwork.Mainnet,
        options: {
          projectId: '119a4be16e4a528159b92cba1d1044aa',
        },
      }),
      new PhantomWalletAdapter(),
      new BackpackWalletAdapter(),
      new FractalWalletAdapter(),
      new OKXWalletAdapter(),
      new TrustWalletAdapter(),
      new SolflareWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new ExodusWalletAdapter(),
      new BitgetWalletAdapter(),
      new XDEFIWalletAdapter(),
      new CloverWalletAdapter(),
      new Coin98WalletAdapter(),
      new MathWalletAdapter(),
      new LedgerWalletAdapter(),
      new MagicEdenWalletAdapter(),
      new NufiWalletAdapter(),
      new KeystoneWalletAdapter(),
      new NightlyWalletAdapter(),
      new TorusWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new TrezorWalletAdapter(),
      new GateWalletAdapter(),
      new BybitWalletAdapter(),
      // new OntoWalletAdapter(),
      // new BitpieWalletAdapter(),
      // new CoinhubWalletAdapter(),
      // new SafePalWalletAdapter(),
      // new HyperPayWalletAdapter(),
      // new SkyWalletAdapter(),
      // new SaifuWalletAdapter(),
      // new SpotWalletAdapter(),
      // new AvanaWalletAdapter(),
      // new KrystalWalletAdapter(),
      // new HuobiWalletAdapter(),
      // new SalmonWalletAdapter(),
    ],
    autoConnect: false,
  })
}
