import { JSTAKING_VOTE } from '~/config'

export const CUSTOM_ROUTES = [
  {
    path: '/flex-staking',
    redirect: '/direct-staking',
  },
  {
    path: '/flex-staking/:vote',
    redirect: ({ params }) => `/direct-staking/${params.vote}`,
  },
  {
    path: '/direct-staking/:vote',
    redirect: ({ params }) => `/direct-staking?vote=${params.vote}`,
  },
  // {
  //   path: '/validators',
  //   redirect: '/direct-staking',
  // },
  // {
  //   path: '/validators/:vote',
  //   redirect: ({ params }) => `/direct-staking/${params.vote}`,
  // },
  {
    path: '/jstaking',
    redirect: `/direct-staking?vote=${JSTAKING_VOTE}`,
  },
]
