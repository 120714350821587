import type { WalletName } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-vue'
import { PhantomWalletName, SolflareWalletName } from 'wallets'
import { OKXWalletName } from 'wallets/adapters/OkxWalletAdapter'

export function useAutoConnect() {
  const route = useRoute()

  const walletName = useLocalStorage('walletName', '')
  const { userAgent, isWebView, isMobileOs } = useMobileDetect()

  const wallet = useWallet()
  const webViewWallet = ref<WalletName | null>(null)

  function detectWebViewWallet() {
    const ua = String(userAgent.value).toLowerCase()
    if (ua.includes('phantom')) {
      return PhantomWalletName
    } else if (globalThis.solflare) {
      return SolflareWalletName
    } else if (/okapp/i.test(ua)) {
      return OKXWalletName
    }
    return null
  }

  onMounted(() => {
    if (isWebView.value) {
      webViewWallet.value = detectWebViewWallet()
    }
  })

  watch(() => route.query?.wallet, (w) => {
    if (w) {
      webViewWallet.value = w as WalletName
    }
  }, { immediate: true })

  watch(webViewWallet, async (w) => {
    if (w) {
      await autoConnect()
    }
  }, { immediate: true })

  async function autoConnect() {
    if (webViewWallet.value) {
      wallet.select(webViewWallet.value)
      await wallet.connect()
      return
    }

    if (!isMobileOs.value && walletName.value && walletName.value?.length !== 0) {
      wallet.select(walletName.value as WalletName)
      await wallet.connect()
    }
  }

  return {
    autoConnect,
    webViewWallet,
  }
}
